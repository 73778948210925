<template>
  <v-container>
    <!-- notification area -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="8">
        <v-row>
          <v-col>
            <h1>
              Task List - {{ $route.query.type | capitalize_first_letter }}
            </h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center" cols="2">
            <p class="mb-0">Filter Tasks:</p>
          </v-col>

          <v-col>
            <v-select
              v-model="selectedFilterByStages"
              :items="stageFilterOptions"
              label="By Stage"
              multiple
            />
          </v-col>

          <v-col>
            <v-select
              v-model="selectedFilterByTypes"
              :items="typeFilterOptions"
              label="By Type"
              multiple
            />
          </v-col>

          <v-col>
            <v-select
              v-model="selectedFilterByServiceTicketTypes"
              :items="serviceTicketTypeFilterOptions"
              label="By Service Ticket Type"
              multiple
            />
          </v-col>

          <v-col>
            <v-select
              v-model="selectedFilterByStatus"
              :items="['On Progress', 'To Do', 'Done', 'Archived']"
              label="By Status"
              multiple
            />
          </v-col>

          <v-col class="d-flex align-center">
            <v-btn @click="handle_clear_filters"> clear filters </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="filteredTasks"
              :items-per-page="-1"
              :search="search"
              item-key="id"
            >
              <template v-slot:[`item.day_limit`]="{ item }">
                <span
                  :class="{
                    'text-red': isTodayOrBefore(item.day_limit, item.status),
                  }"
                >
                  {{ item.day_limit }}
                </span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex align-center">
                  <v-btn
                    @click="
                      handle_update_task(
                        item.stg_obj.stg_itm.type,
                        item.stg_obj.stg_itm.id,
                        item.stg_obj.id,
                        item.id
                      )
                    "
                    title="Edit Task"
                    icon
                  >
                    <v-icon color="grey"> mdi-pencil-outline </v-icon>
                  </v-btn>

                  <v-btn
                    @click="
                      $router.push({
                        path: `/dashboard/admin/kanban/Deal/stage-item/${item.stg_obj.stg_itm.id}/objective/read/${item.stg_obj.id}`,
                      })
                    "
                    title="View Objective"
                    icon
                  >
                    <v-icon color="grey"> mdi-bullseye-arrow </v-icon>
                  </v-btn>

                  <v-btn
                    @click="
                      $router.push({
                        path: `/dashboard/admin/deal/read/${item.stg_obj.stg_itm.deal.id}`,
                      })
                    "
                    title="View Deal"
                    icon
                  >
                    <v-icon color="grey"> mdi-handshake </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-dialog
          v-model="update_task"
          fullscreen
          transition="dialog-bottom-transition"
        >
          <v-card style="background-color: #111">
            <v-toolbar color="orange darken-3">
              <v-toolbar-title>
                <v-icon>mdi-clipboard-edit-outline</v-icon>
                Edit Task
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="update_task = false" title="Click to close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <UpdateTask
              ref="updateTaskRef"
              v-if="temp_task_id"
              view_type="pop_up"
              :kanban_type="temp_kanban_type"
              :stage_item_id="temp_stage_item_id"
              :stage_objective_id="temp_stage_objective_id"
              :stage_objective_task_id="temp_task_id"
              @close="
                temp_task_id = null;
                update_task = false;
                getTasks($route.query.type);
              "
            />
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import UpdateTask from "@/views/admin/kanban/objective/task/ReadTask.vue";

export default {
  components: {
    UpdateTask,
  },

  data() {
    return {
      loading: false,
      error: false,
      errorMessage: "",

      search: "",

      selectedFilterByStages: [],
      selectedFilterByTypes: [],
      selectedFilterByServiceTicketTypes: [],
      selectedFilterByStatus: ["On Progress", "To Do"],

      temp_kanban_type: null,
      temp_stage_item_id: null,
      temp_stage_objective_id: null,
      temp_task_id: null,
      update_task: false,

      stageFilterOptions: [],
      typeFilterOptions: ["Internal Step", "Service Ticket"],
      serviceTicketTypeFilterOptions: [
        "Payout Request",
        "Balance Request",
        "Payment Amount Change",
        "Payment Date Change",
        "Payment Collection",
        "Property Taxes",
        "Insurance",
        "Demand Letter",
        "Foreclosure",
        "Other",
      ],

      tasks: [],
      headers: [
        { text: "Due Date", value: "day_limit", width: "130" },
        { text: "Task Subject", value: "subject" },
        { text: "Status", value: "status" },
        { text: "Customer", value: "customer" },
        { text: "Objective", value: "stg_obj.subject" },
        { text: "Type", value: "type" },
        { text: "Stage", value: "stg_itm" },
        { text: "Actions", value: "actions" },
      ],
    };
  },

  computed: {
    filteredTasks() {
      let result = [];

      this.tasks.forEach((task) => {
        let customer_name = "";
        let type = "";

        if (task.stg_obj.stg_itm.deal.int_custs.find((c) => c.main_applicant && c.customer)) {
          customer_name =
            task.stg_obj.stg_itm.deal.int_custs.find((c) => c.main_applicant)
              .customer.first_name +
            task.stg_obj.stg_itm.deal.int_custs.find((c) => c.main_applicant)
              .customer.last_name;
        } else {
          customer_name = "No customer found";
        }

        if (task.stg_obj.type === "Service Ticket") {
          if (task.stg_obj.service_ticket_type === "Other") {
            type =
              task.stg_obj.type +
              " - " +
              task.stg_obj.service_ticket_type +
              " - " +
              task.stg_obj.specified_service_ticket_type;
          } else {
            type = task.stg_obj.type + " - " + task.stg_obj.service_ticket_type;
          }
        } else {
          type = task.stg_obj.type;
        }

        result.push({
          id: task.id,
          day_limit: this.convertObjectiveDayLimit(task),
          customer: customer_name,
          subject: task.subject,
          stg_obj: task.stg_obj,
          stg_itm: task.stg_obj.stg_itm.kbn_stg.stage_name,
          type: type,
          status: task.status,
          objective_type: task.stg_obj.type,
          service_ticket_type: task.stg_obj.service_ticket_type,
        });
      });

      if (this.selectedFilterByStages.length > 0) {
        result = result.filter((task) =>
          this.selectedFilterByStages.includes(task.stg_itm)
        );
      }

      if (this.selectedFilterByTypes.length > 0) {
        result = result.filter((task) =>
          this.selectedFilterByTypes.includes(task.objective_type)
        );
      }

      if (this.selectedFilterByServiceTicketTypes.length > 0) {
        result = result.filter((task) =>
          this.selectedFilterByServiceTicketTypes.includes(
            task.service_ticket_type
          )
        );
      }

      if (this.selectedFilterByStatus.length > 0) {
        result = result.filter((task) =>
          this.selectedFilterByStatus.includes(task.status)
        );
      }

      // Sort tasks by status
      result.sort((a, b) => {
        const statusOrder = ["On Progress", "To Do", "Done"];
        return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      });

      return result;
    },

    userID() {
      // Access the user ID from the Vuex store getter
      return this.$store.getters["Auth/getAuthUser"].id;
    },
  },

  methods: {
    isTodayOrBefore(date, status) {
      if (status === "Done") {
        return false;
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const itemDate = new Date(date);
      itemDate.setHours(0, 0, 0, 0);
      return itemDate <= today;
    },

    handle_update_task(
      kanban_type,
      stage_item_id,
      stage_objective_id,
      task_id
    ) {
      this.temp_kanban_type = kanban_type;
      this.temp_stage_item_id = stage_item_id;
      this.temp_stage_objective_id = stage_objective_id;
      this.temp_task_id = task_id;

      this.update_task = true;

      this.$nextTick(() => {
        this.$refs.updateTaskRef.readStageObjectiveTask();
      });
    },

    handle_clear_filters() {
      this.selectedFilterByStages = [];
      this.selectedFilterByTypes = [];
      this.selectedFilterByServiceTicketTypes = [];
      this.selectedFilterByStatus = [];
    },

    convertObjectiveDayLimit(item) {
      const date = new Date(item.createdAt);
      date.setDate(date.getDate() + item.day_limit);

      const year = date.getFullYear();
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
      const result = `${month} ${day}, ${year}`;

      return result;
    },

    async getTasks(view_type) {
      try {
        this.loading = true;

        const response = await API().get(
          `api/internal-admin/read_all_user_tasks?user_id=${this.userID}&type=${view_type}`
        );

        if (response) {
          this.getStages();
          // console.log("All tasks: ", response.data);

          this.tasks = response.data;

          this.loading = false;
        } else {
          this.error = true;
          this.errorMessage = "No tasks found";
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.loading = false;
      }
    },

    async getStages() {
      try {
        const kanban_stages = await API().get(
          "api/kanban/get_kanban_deal_data"
        );

        kanban_stages.data.forEach((el) => {
          this.stageFilterOptions.push(el.stage_name);
        });
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    const view_type = this.$route.query.type;

    this.getTasks(view_type);
  },

  filters: {
    ...filt,
  },
};
</script>

<style scoped>
.text-red {
  color: red;
}
</style>
